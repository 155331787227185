import axios from 'axios'
import apiConfig from '../config/apiConfig'

const headerConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
}
const uploadConfig = (checkSum) => {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'md5-checksum': checkSum,
    },
  }
}

const downloadConfig = {
  headers: {
    'Content-Type': 'application/octet-stream',
    Accept: 'application/octet-stream',
  },
  responseType: 'arraybuffer',
}

const downloadAllConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/octet-stream',
  },
  responseType: 'arraybuffer',
}

// Survey Form Rest Calls
export const getAllSurveys = async (filterOption) => {
  const config = await apiConfig()
  return axios.get(
    `${config.fieldAssessment.host}/survey_forms?filter_option=` +
      filterOption +
      `&key=` +
      config.key,
    headerConfig,
  )
}

export const getSurveyFormsBySurveyId = async (id) => {
  const config = await apiConfig()
  return axios.get(
    `${config.fieldAssessment.host}/survey_forms/` + id + `?key=` + config.key,
    headerConfig,
  )
}

export const createSurveyForm = async (request) => {
  const config = await apiConfig()
  return axios.post(
    config.fieldAssessment.host + '/survey_forms?key=' + config.key,
    JSON.stringify(request),
    headerConfig,
  )
}
export const updateSurveyForm = async (id, request) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host + '/survey_forms/' + id + '?key=' + config.key,
    JSON.stringify(request),
    headerConfig,
  )
}

export const softDeleteSurvey = async (surveyId) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host +
      '/survey_forms/delete/' +
      surveyId +
      '?key=' +
      config.key,
    headerConfig,
  )
}

// Survey Answer Rest Calls
export const getSurveryResponseDataByDeploymentId = async (deploymentId) => {
  const config = await apiConfig()
  return axios.get(
    `${config.fieldAssessment.host}/survey_answers/deployments/` +
      deploymentId +
      `?key=` +
      config.key,
    headerConfig,
  )
}

export const getSurveryResponseByDeploymentIdAndUserId = async (
  deploymentId,
) => {
  const config = await apiConfig()
  return axios.get(
    `${config.fieldAssessment.host}/survey_answers/deployments_and_answers/` +
      deploymentId +
      `?key=` +
      config.key,
    headerConfig,
  )
}

export const createSurveryResponse = async (request) => {
  const config = await apiConfig()
  return axios.post(
    config.fieldAssessment.host + '/survey_answers?key=' + config.key,
    JSON.stringify(request),
    headerConfig,
  )
}
export const updateSurveryResponse = async (request, surveyAnswerId) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host +
      `/survey_answers/` +
      surveyAnswerId +
      `?key=` +
      config.key,
    JSON.stringify(request),
    headerConfig,
  )
}

export const deleteSurveyAnswerById = async (surveyAnswerId) => {
  const config = await apiConfig()
  return axios.post(
    `${config.fieldAssessment.host}/survey_answers/delete/` +
      surveyAnswerId +
      `?key=` +
      config.key,
    headerConfig,
  )
}

// Deployment rest calls
export const saveDeployment = async (request) => {
  const config = await apiConfig()
  return axios.post(
    config.fieldAssessment.host + '/deployments?key=' + config.key,
    JSON.stringify(request),
    headerConfig,
  )
}
export const getAllDeployments = async (status) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/deployments/all?status=' +
      status +
      '&key=' +
      config.key,
    headerConfig,
  )
}
export const getAllDeploymentsByEmail = async (status) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/deployments?status=' +
      status +
      '&key=' +
      config.key,
    headerConfig,
  )
}

export const getFormByDeploymentId = async (deploymentId) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/deployments/survey_forms/' +
      deploymentId +
      '?key=' +
      config.key,
    headerConfig,
  )
}

export const getFormAndAnswerByDeploymentId = async (
  deploymentId,
  answerId,
) => {
  const config = await apiConfig()
  var url =
    config.fieldAssessment.host +
    '/deployments/survey_forms_and_survey_answer/' +
    deploymentId
  if (answerId !== null && answerId !== undefined) {
    url = url + '?survey_answer_id=' + answerId
  }
  return axios.get(url, headerConfig)
}

export const getDeploymentById = async (deploymentId) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/deployments/' +
      deploymentId +
      '?key=' +
      config.key,
    headerConfig,
  )
}

export const updateDeployment = async (request, id) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host + '/deployments/' + id + '?key=' + config.key,
    JSON.stringify(request),
    headerConfig,
  )
}

export const updateDeploymentStatus = async (id, status) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host + '/deployments/' + id + '/status/' + status,
    headerConfig,
  )
}

export const applyFormChangesToDeployment = async (request, surveyId) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host + '/deployments/apply_form_changes/' + surveyId,
    JSON.stringify(request),
    headerConfig,
  )
}

export const softDeleteDeployment = async (deploymentId) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host +
      '/deployments/delete/' +
      deploymentId +
      '?key=' +
      config.key,
    headerConfig,
  )
}

// Custom Groups rest calls
export const getAllCustomGroups = async () => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host + '/custom_groups?key=' + config.key,
  )
}

export const saveCustomGroup = async (request) => {
  const config = await apiConfig()
  return axios.post(
    config.fieldAssessment.host + '/custom_groups?key=' + config.key,
    JSON.stringify(request),
    headerConfig,
  )
}

export const getCustomGroupById = async (groupId) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/custom_groups/' +
      groupId +
      '?key=' +
      config.key,
    headerConfig,
  )
}

export const updateCustomGroup = async (request, id) => {
  const config = await apiConfig()
  return axios.put(
    config.fieldAssessment.host + '/custom_groups/' + id + '?key=' + config.key,
    JSON.stringify(request),
    headerConfig,
  )
}

// Recipient rest calls
export const getRecipientsByEmailId = async (email) => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/users?work_email=' +
      email +
      '&key=' +
      config.key,
    headerConfig,
  )
}

export const getBusinessPartners = async () => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host + '/business_partners?key=' + config.key,
    headerConfig,
  )
}

//File section
export const uploadFile = async (files, checkSum) => {
  const config = await apiConfig()
  return axios.post(
    `${config.fieldAssessment.host}/files/upload?key=` + config.key,
    files,
    uploadConfig(checkSum),
  )
}
export const downloadFile = async (fileName, deploymentMode) => {
  const config = await apiConfig()
  return axios.get(
    `${config.fieldAssessment.host}/files/download?file_name=` +
      fileName +
      '&deployment_mode=' +
      deploymentMode,
    downloadConfig,
  )
}

export const downloadFileBulk = async (request) => {
  const config = await apiConfig()
  //console.log("rest")
  return axios.post(
    `${config.fieldAssessment.host}/files/download/all?key=` + config.key,
    JSON.stringify(request),
    downloadAllConfig,
  )
}

// Location section
export const getAllLocations = async () => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host + '/locations?&key=' + config.key,
    headerConfig,
  )
}
export const getAllUsers = async () => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host +
      '/users/all_users?pageNo=0&pageSize=150000&' +
      'key=' +
      config.key,
    headerConfig,
  )
}

//Role Identities
export const getAllRoleIdentities = async () => {
  const config = await apiConfig()
  return axios.get(
    config.fieldAssessment.host + '/role_identities',
    headerConfig,
  )
}
